"use client";
import { signIn } from 'next-auth/react';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';

const Login = () => {
  const router = useRouter();
  const [callbackUrl, setCallbackUrl] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const url = searchParams.get('callbackUrl');
    setCallbackUrl(url ? decodeURIComponent(url) : document.referrer);
  }, []);

  const handleSignIn = () => {
    signIn('github', {
      callbackUrl: callbackUrl || '/',
    });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-6">Login</h1>
        <button
          onClick={handleSignIn}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Sign in with GitHub
        </button>
      </div>
    </div>
  );
};

export default Login;
